<template>
  <circle-loader v-if="isLoading" />
  <div class="procure" v-else>
    <div class="procure_header">
      <h1>Update shipment</h1>
    </div>
    <div class="procure_inner">
      <h1>Business Name: {{ item?.businessName }}</h1>

      <div class="item_details">
        <div>
          <p>Product name</p>
          <p>{{ item?.data?.name }}</p>
        </div>

        <div>
          <p>Tracking number</p>
          <p>{{ item?.data?.trackingNumber }}</p>
        </div>

        <div>
          <p>Weight</p>
          <div class="input">
            <input
              v-model="weightOfGood"
              required
              type="number"
              min="0"
              placeholder="0"
              class="input_field"
            />
          </div>
          <!-- <p>{{ item?.data?.weight }}{{ item?.data?.weightUnit }}</p> -->
        </div>
        <div>
          <p>Weight Unit</p>
          <p>{{ item?.data?.weightUnit }}</p>
        </div>

        <div>
          <p>Quantity</p>
          <p>{{ item?.data?.quantity }}</p>
        </div>

        <div>
          <p>Goods category</p>
          <p>{{ shipmentCategory?.title }}</p>
        </div>

        <div>
          <p>Freight Type</p>
          <p>{{ item?.data?.freightType }}</p>
        </div>

        <div>
          <p>Pick up Address</p>
          <p>{{ originPickupAddress?.unstructuredAddress }}</p>
        </div>

        <div>
          <p>Shipment country</p>
          <p>{{ originPickupAddress?.country }}</p>
        </div>

        <div>
          <p>Drop off Address</p>
          <p>{{ destinationDropOffAddress?.unstructuredAddress }}</p>
        </div>

        <div>
          <p>Destination country</p>
          <p>{{ destinationDropOffAddress?.country }}</p>
        </div>

        <div>
          <p>Dangerous Good</p>
          <p>{{ item?.data?.isDangerous }}</p>
        </div>

        <div>
          <p>Enter shipment price</p>
          <div class="input">
            <input
              v-model="priceOfGood"
              required
              type="number"
              min="0"
              placeholder="0"
              class="input_field"
            />
          </div>
        </div>

        <div>
          <p>Update Status</p>
          <div class="shipments_select">
            <select required v-model="status" class="input_field">
              <option value="">Select status</option>
              <option value="PENDING">PENDING</option>
              <option value="RECEIVED">RECEIVED</option>
              <option value="SHIPPED">SHIPPED</option>
              <option value="AWAITING_DELIVERY">AWAITING DELIVERY</option>
              <option value="DELIVERED">DELIVERED</option>
            </select>
          </div>
        </div>

        <div v-if="this.isImageAvailable === ''" class="input_image">
          <input
            type="file"
            ref="fileInput"
            id="file-input"
            required
            @change="handleFileUpload"
            accept="image/png, image/gif, image/jpeg"
            style="display: none"
          />
          <div>
            <label
              v-if="!productImage"
              @click="handleFileInputClick"
              for="file-input"
            >
              Attach item picture
            </label>
            <label v-else @click="handleFileInputClick" for="file-input">
              Choose another item picture
            </label>
          </div>
          <div class="images_wrapper">
            <div class="image_wrapper">
              <img
                :src="productImage"
                alt="Preview Image"
                v-if="productImage"
              />
            </div>
          </div>
        </div>

        <div v-else class="shipment_uploaded_image">
          <div class="shipment_uploaded_image_wrapper">
            <img :src="this.isImageAvailable" alt="edited image" />
          </div>
        </div>

        <div class="btn_wrapper">
          <div class="button">
            <button @click="submitShipmentEdit" class="button_btn">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleLoader from "../../components/loader/CircleLoader.vue";
import Shipment from "@/utils/api/shipment/ShipmentApi";

export default {
  data() {
    return {
      isLoading: false,
      item: [],
      shipmentCategory: [],
      destinationDropOffAddress: [],
      originPickupAddress: [],
      statusOfOrder: [],
      status: "",
      productImage: "",
      imageFile: null,
      imageUrl: "",
      fileName: "",
      priceOfGood: "",
      weightOfGood: "",
      isImageAvailable: "",
    };
  },
  components: {
    CircleLoader,
  },
  mounted() {
    this.fetchUserShipment();
  },
  methods: {
    // handleFileUpload(event) {
    //   const imgFile = event.target.files[0];

    //   this.imageFile = imgFile;

    //   this.fileName = imgFile.name;

    //   if (imgFile) {
    //     const reader = new FileReader();

    //     reader.onload = (event) => {
    //       this.productImage = event.target.result;
    //       // Check if image exist in array, if not, add to the array
    //       if (
    //         !this.images.includes(this.productImage) &&
    //         this.images.length < 1
    //       ) {
    //         this.images.push(this.productImage);
    //       } else {
    //         return;
    //       }
    //     };
    //     reader.readAsDataURL(imgFile);
    //   }
    // },

    removeBase64Prefix(base64String) {
      // Check if the string starts with the prefix
      if (base64String.startsWith("data:image/png;base64,")) {
        // Remove the prefix from the string
        return base64String.slice("data:image/png;base64,".length);
      } else if (base64String.startsWith("data:image/jpeg;base64,")) {
        return base64String.slice("data:image/jpeg;base64,".length);
      } else {
        // Return the original string if it doesn't start with the prefix
        return base64String;
      }
    },

    handleFileUpload(event) {
      const imgFile = event.target.files[0];

      this.imageFile = imgFile;

      this.fileName = imgFile.name;

      if (imgFile) {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.productImage = event.target.result;
          this.imageUrl = this.removeBase64Prefix(event.target.result);
        };
        reader.readAsDataURL(imgFile);
      }
    },

    async submitShipmentEdit() {
      const token = this.$store.state.accessToken;

      const shipmentId = this.$route.params.itemId;
      try {
        const editedShipmentRes = await Shipment.updateShipment({
          shipmentId,
          access_token: token,
          image: this.imageUrl,
          price: this.priceOfGood,
          status: this.status,
          weight: this.weightOfGood,
        });
        this.$toast.success(editedShipmentRes.data.data);

        this.$router.push("/shipments");
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },

    async fetchUserShipment() {
      const token = this.$store.state.accessToken;

      const shipmentId = this.$route.params.itemId;
      this.isLoading = true;
      try {
        const userShipmentRes = await Shipment.getSingleShipment({
          shipmentId,
          access_token: token,
        });

        // get warehouses and filter to what is gotten from backend
        const getWarehouses = await Shipment.getWarehouse({
          access_token: token,
        });

        this.originPickupAddress = getWarehouses.data.data.find(
          (location) =>
            location._id === userShipmentRes.data.data.pickUpWareHouseAddress
        );

        this.destinationDropOffAddress = getWarehouses.data.data.find(
          (location) =>
            location._id === userShipmentRes.data.data.dropOffwarehouseAddress
        );

        // Check for the current status of the shipment
        const status = userShipmentRes.data.data.status;
        this.statusOfOrder = this.computeStatusOfOrder(status);

        // Automatically set the status to the current one.
        this.status = status;

        let weight = userShipmentRes.data.data.weight;

        this.priceOfGood = userShipmentRes.data.data.price;

        this.weightOfGood = weight;

        // get shipment categories and filter to what is gotten from backend
        const shipmentCategories = await Shipment.getCategory({
          access_token: token,
        });

        this.shipmentCategory = shipmentCategories.data.data.find(
          (category) => category._id === userShipmentRes.data.data.category
        );

        this.item = userShipmentRes.data;

        this.isImageAvailable =
          userShipmentRes?.data?.data?.image !== null
            ? userShipmentRes?.data?.data?.image
            : "";
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },

    computeStatusOfOrder(status) {
      switch (status) {
        case "PROCESSED":
          return ["PROCESSED"];
        case "SHIPPED":
          return ["PROCESSED", "SHIPPED"];
        case "CLEARANCE":
          return ["PROCESSED", "SHIPPED", "CLEARANCE"];
        case "SORTED":
          return ["PROCESSED", "SHIPPED", "CLEARANCE", "SORTED"];
        case "DELIVERED":
          return ["PROCESSED", "SHIPPED", "CLEARANCE", "SORTED", "DELIVERED"];
        default:
          return [];
      }
    },

    getFillColor(status) {
      // Check if the status is included in the statusOfOrder array
      return this.statusOfOrder.includes(status) ? "#7EBAD2" : "#FAFAFA";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.procure {
  width: 100%;
  height: auto;
  padding: 1.75rem 5.125rem;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .procure_header {
    margin-bottom: 2.8125rem;

    @media only screen and (max-width: 920px) {
      margin-bottom: 1.5625rem;
    }
  }

  .procure_inner {
    display: flex;
    padding: 0.3125rem 5.125rem;
    flex: 1;
    flex-direction: column;
    height: auto;
    flex-shrink: 0;
    border-radius: 1.875rem;
    gap: 2.8125rem;
    border: 1px solid #dedce9;
    background: #fafafa;

    @media only screen and (max-width: 1280px) {
      max-width: 770px;
      height: auto;
    }

    @media only screen and (max-width: 920px) {
      overflow: visible;
      gap: 1.5625rem;
      padding: 0rem 0.5em;
    }

    h1 {
      margin-top: 4.875rem;
      color: #000;
      font-family: Moderat;
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .item_details {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5625rem;
      width: 40.8125em;

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      .input_image {
        width: 100%;
        height: 218px;
        flex-shrink: 0;
        border-radius: 1.25rem;
        border: 0.0625rem solid #c4c1c1;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        justify-content: center;

        .images_wrapper {
          display: flex;
          width: 40.8125em;
          overflow-x: scroll;
          gap: 0.75rem;

          .image_wrapper {
            width: 200px;
            height: 150px;
            display: flex;
            @media only screen and (max-width: 920px) {
              width: 100px;
              height: 100px;
            }
            img {
              display: flex;
              width: 100%;
              height: auto;
              object-fit: contain;
            }
          }
        }

        div {
          width: 100%;
          height: auto;
          position: relative;
          align-items: center;
          display: flex;
          justify-content: center;

          label {
            cursor: pointer;
            z-index: 100;
          }
        }
        @media only screen and (max-width: 920px) {
          width: 100%;
        }
      }

      .shipment_uploaded_image {
        display: flex;
        flex-direction: column;
        width: 257px;
        height: 198px;
        border-radius: 5px;

        .shipment_uploaded_image_wrapper {
          display: flex;
          flex-direction: column;
          width: 257px;
          height: 198px;

          img {
            width: 100%;
            height: auto;
            display: flex;
          }
        }
      }

      .input {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 0.625rem;
        width: 50%;

        input {
          border-radius: 10px;
          background-color: #fefefe;
          width: 100%;
          height: auto;
          display: flex;
          flex: 1;
          color: #363539;
          border: none;
          border: 1px solid #dedce9;
          padding: 0.875rem 1rem;
        }
      }

      .button {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        button {
          display: flex;
          width: 9.375em;
          height: 2.5625em;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }
      }

      .shipments_select {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 0.625rem;
        width: 50%;

        select {
          border-radius: 10px;
          background-color: #fefefe;
          width: 100%;
          height: auto;
          display: flex;
          flex: 1;
          color: #363539;
          border: none;
          border: 1px solid #dedce9;
          padding: 0.875rem 1rem;

          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          appearance: none;
          -webkit-appearance: none;

          &:focus {
            outline: none;
          }
        }
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      div {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p:nth-child(1) {
          color: #626262;
          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.9375rem;
          }
        }

        p:nth-child(2) {
          flex: 1;
          max-width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #1d1d1f;
          font-family: Inter;
          font-size: 0.9375rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }

        a {
          flex: 1;
          max-width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #2d5795;
          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }
      }
    }

    .items_timeline {
      width: 100%;
      display: flex;
      gap: 2.0625rem;
      flex-direction: column;

      h1 {
        color: #000;
        font-family: Moderat;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      div {
        display: flex;
        width: 750px;

        @media only screen and (max-width: 1280px) {
          width: 100%;
        }

        .items_timeline_time {
          display: flex;
          gap: 0.4375rem;
          flex-direction: column;
          align-items: center;
          width: 6.25em;

          p:nth-child(2) {
            color: #626262;
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @media only screen and (max-width: 920px) {
              font-size: 0.75rem;
            }
          }

          p:nth-child(3) {
            color: #626262;
            font-family: Inter;
            font-size: 0.75rem;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media only screen and (max-width: 920px) {
              font-size: 0.625rem;
            }
          }
        }

        .items_timeline_gap {
          width: 7.875em;
          height: 0.9375em;
          border-bottom: 0.125rem dotted #626262;
        }
      }
    }
  }
}
</style>
