<template>
  <div class="shipments">
    <div class="shipments_side_menu">
      <side-menu />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import SideMenu from "../../components/menu/SideMenu.vue";
export default {
  components: {
    SideMenu,
  },
};
</script>

<style lang="scss" scoped>
.shipments {
  display: flex;
  height: 100vh;
  overflow: scroll;

  .shipments_side_menu {
    @media only screen and (max-width: 920px) {
      display: none;
    }
  }
}
</style>
