import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

const ExchangeRatesApi = {
  getAllRates: () => {
    const res = axios({
      url: `${baseUrl}/admin/exchange-rates`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  createRate: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/exchange-rate/create`,
      method: "POST",
      data: JSON.stringify({
        currency: payload.currency,
        currencyAbbreviation: payload.currencyAbbreviation,
        amount: payload.amount,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  updateCurrencyRate: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/exchange-rate/update/${payload.rate_id}`,
      method: "PUT",
      data: JSON.stringify({
        currency: payload.currency,
        currencyAbbreviation: payload.currencyAbbreviation,
        amount: payload.amount,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export default ExchangeRatesApi;
