<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <circle-loader v-if="isLoading" />
  <div class="procure" v-else>
    <div class="procure_inner">
      <h1>Create a new exchange rate</h1>

      <form class="procure_form" @submit.prevent="createExchangeRatesHandler">
        <div class="shipments_select">
          <label>Currency</label>
          <div class="input">
            <input
              v-model="currency"
              required
              type="text"
              placeholder="Enter currency name"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Currency Abbreviation</label>
          <div class="input">
            <input
              v-model="currencyAbbreviation"
              required
              type="text"
              placeholder="Enter currency abbreviation link "
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Rate in NGN</label>
          <div class="input">
            <input
              v-model="rate"
              required
              type="number"
              min="0"
              placeholder="Enter rate in naira"
              class="input_field"
            />
          </div>
        </div>

        <div v-if="!submitLoading" class="button">
          <button class="button_btn">Submit</button>
        </div>

        <div v-else class="button">
          <button :disabled="submitLoading" class="button_btn">
            <div class="loading-circle"></div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ExchangeRatesApi from "@/utils/api/Exchange-rates/ExchangeRatesApi";
import CircleLoader from "../../components/loader/CircleLoader.vue";

export default {
  data() {
    return {
      currency: "",
      currencyAbbreviation: "",
      rate: "",
      isLoading: false,
      item: null,
      submitLoading: false,
    };
  },
  components: {
    CircleLoader,
  },
  mounted() {},
  methods: {
    async createExchangeRatesHandler() {
      const token = this.$store.state.accessToken;

      this.submitLoading = true;

      try {
        const exchangeRateRes = await ExchangeRatesApi.createRate({
          currency: this.currency,
          currencyAbbreviation: this.currencyAbbreviation,
          amount: this.rate,
          access_token: token,
        });

        this.submitLoading = true;

        this.$toast.success("Form has been submitted successfully");

        // route to procurement list page after successful procurement of item
        this.$router.push("/exchangeRates");

        return exchangeRateRes.data;
      } catch (err) {
        this.submitLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.procure {
  width: 100%;
  padding: 2.75rem 5.125em;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .procure_inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 53.3125em;
    flex-shrink: 0;
    padding: 1.5625rem 0rem;
    border-radius: 1.875rem;
    gap: 2.8125rem;
    border: 1px solid #dedce9;
    background: #fafafa;
    align-items: center;

    @media only screen and (max-width: 920px) {
      overflow: visible;
    }

    h1 {
      margin-top: 2.1875rem;
      color: #000;
      font-family: $primary-font;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media only screen and (max-width: 920px) {
        font-size: 1.5625rem;
        margin-top: 0.3125rem;
      }
    }

    .procure_form {
      display: flex;
      flex-direction: column;
      gap: 2.8125rem;
      width: 29.1875em;
      overflow: scroll;

      .loading-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
        position: relative;
        animation: rotate 1s linear infinite;
      }

      .loading-circle::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
        animation: bounce 0.6s linear infinite;
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      @keyframes bounce {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(0);
        }
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
        padding: 0px 0.5rem;
      }

      .shipments_image {
        border-radius: 0.625rem;
        width: 100%;
        height: 9.1875em;
        gap: 0.625rem;
        display: flex;
        flex-direction: column;

        .input_image {
          width: 100%;
          height: 9.1875em;
          flex-shrink: 0;
          border-radius: 0.3125rem;
          border: 0.0625rem solid #c4c1c1;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          justify-content: center;

          img {
            width: 100%;
            height: 100px;
            object-fit: contain;
          }
          div {
            width: 100%;
            height: auto;
            position: relative;
            align-items: center;
            display: flex;
            justify-content: center;
            // background: blue;

            label {
              cursor: pointer;
              z-index: 100;
            }
          }
          @media only screen and (max-width: 920px) {
            width: 100%;
          }
        }

        label {
          color: #606060;
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .shipments_select:nth-child(4) {
        height: 6.9375em;

        .input_textarea {
          height: 6.9375em;
          border-radius: 0.3125rem;
          border: 0.0625rem solid #c4c1c1;

          & textarea {
            width: 100%;
            resize: none;
            flex: 1;
            height: 6.75em;
            display: flex;
            border: none;
            border-radius: 0.3125rem;
            padding: 0.875rem 1rem;

            color: $black-color;
            font-family: $secondary-font;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:focus {
              outline: none;
            }
          }
        }
      }
      .shipments_select {
        border-radius: 0.625rem;
        width: 100%;
        height: 3.125em;
        gap: 0.625rem;
        display: flex;
        flex-direction: column;

        label {
          color: #606060;
          font-family: $secondary-font;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
