<template>
  <div class="overlay">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.overlay {
  height: 100vh;
  width: 100vw;
  display: flex;

  .loader {
    display: flex;
    margin: auto;
    border: 4px solid #554b9584; /* Adjust border color and width as needed */
    border-top: 4px solid #554b95; /* Spinner color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
