import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

const Procurement = {
  getAllProcurements: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/procurements`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getSingleProcurement: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/procurement/${payload.procurementId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  updateProcurement: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/procurement/edit/${payload.procurementId}`,
      method: "PUT",
      data: JSON.stringify({
        status: payload.status,
        amount: payload.amount,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export default Procurement;
