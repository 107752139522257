import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

const Shipment = {
  getAllShipments: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/shipments/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getSingleShipment: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/shipment/${payload.shipmentId}/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  updateShipment: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/shipment/update/${payload.shipmentId}`,
      method: "PUT",
      data: JSON.stringify({
        image: payload.image,
        price: payload.price,
        weight: payload.weight,
        status: payload.status,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  queryShipmentByStatus: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/shipments/query?status=${payload.status}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getCategory: () => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment/category`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getWarehouse: () => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment/warehouse`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export default Shipment;
