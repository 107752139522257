<template>
  <circle-loader v-if="isLoading" />
  <div class="procure" v-else>
    <div class="procure_inner">
      <h1>Business Name: {{ item?.businessName }}</h1>

      <div class="item_details">
        <div>
          <p>ID</p>
          <p>{{ item?.data?.listingId }}</p>
        </div>

        <div>
          <p>Name</p>
          <p>{{ item?.data?.productName }}</p>
        </div>

        <div>
          <p>Description</p>
          <p>{{ item?.data?.productDescription }}</p>
        </div>

        <div>
          <p>Quantity</p>
          <p>{{ item?.data?.quantity }}</p>
        </div>

        <div>
          <p>Status</p>
          <p>
            {{
              item?.data?.status?.charAt(0)?.toUpperCase() +
              item?.data?.status?.slice(1)
            }}
          </p>
        </div>

        <div>
          <p>Product link</p>
          <a :href="item?.data?.productLink" target="_blank">{{
            item?.data?.productLink?.substring(0, 70)
          }}</a>
        </div>

        <div>
          <p>Update Status</p>
          <div class="shipments_select">
            <select required v-model="status" class="input_field">
              <option value="">Select status</option>
              <option value="PROCURED">PROCURED</option>
              <option value="PENDING">PENDING</option>
              <option value="LOGGED">LOGGED</option>
            </select>
          </div>
        </div>

        <div>
          <p>Enter price</p>
          <div class="input">
            <input
              v-model="priceOfGood"
              required
              type="number"
              min="0"
              placeholder="0"
              class="input_field"
            />
          </div>
        </div>
      </div>

      <div class="procure_item_image">
        <div class="procure_item_image_inner">
          <img :src="item?.data?.productImage" alt="procurement_image" />
        </div>
      </div>

      <div class="btn_wrapper" @click="updateRecordHandler">
        <div class="button">
          <button
            :disabled="priceOfGood?.length <= 0 || status === 'LOGGED'"
            class="button_btn"
          >
            Update Record
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Procurement from "@/utils/api/procurement/ProcurementApi";
import CircleLoader from "../../components/loader/CircleLoader.vue";

export default {
  data() {
    return {
      isLoading: false,
      item: null,
      status: "",
      priceOfGood: "",
    };
  },
  components: {
    CircleLoader,
  },
  mounted() {
    this.fetchUserProcurements();
  },
  methods: {
    // handleFileUpload(event) {
    //   const invoice_file = event.target.files;

    //   const pdfFile = invoice_file.item(0);

    //   const formdata = new FileReader();

    //   formdata.readAsDataURL(pdfFile);

    //   formdata.onload = (event) => {
    //     this.invoice = event.target.result;
    //   };
    // },

    async updateRecordHandler() {
      const token = this.$store.state.accessToken;

      const procurementId = this.$route.params.itemId;
      try {
        const editedProcurementRes = await Procurement.updateProcurement({
          procurementId,
          access_token: token,
          status: this.status,
          amount: this.priceOfGood,
        });
        this.$toast.success("Item updated successfully");

        this.$router.push("/procurement");
        return editedProcurementRes?.data?.data;
      } catch (err) {
        this.$toast.error(err.response?.data?.message);
      }
    },

    async fetchUserProcurements() {
      const token = this.$store.state.accessToken;

      const procurementId = this.$route.params.itemId;

      this.isLoading = true;
      try {
        const userProcurementRes = await Procurement.getSingleProcurement({
          procurementId,
          access_token: token,
        });

        this.isLoading = false;
        this.item = userProcurementRes.data;

        this.status = userProcurementRes.data.data.status;

        this.priceOfGood = userProcurementRes.data.data.amount;
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.procure {
  width: 100%;
  padding: 2.75rem 5.125rem;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .procure_inner {
    display: flex;
    padding: 0.875rem 5.125rem;
    flex: 1;
    flex-direction: column;
    height: auto;
    flex-shrink: 0;
    border-radius: 1.875rem;
    gap: 2.8125rem;
    border: 1px solid #dedce9;
    background: #fafafa;

    @media only screen and (max-width: 1280px) {
      max-width: 770px;
    }

    @media only screen and (max-width: 920px) {
      overflow: visible;
      gap: 1.5625rem;
      padding: 0rem 0.5em;
    }

    h1 {
      margin-top: 2.5rem;
      color: #000;
      font-family: Moderat;
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .item_details {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5625rem;
      width: 40.8125em;

      .shipments_select {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 0.625rem;
        width: 50%;

        select {
          border-radius: 10px;
          background-color: #fefefe;
          width: 100%;
          height: auto;
          display: flex;
          flex: 1;
          color: #363539;
          border: none;
          border: 1px solid #dedce9;
          padding: 0.875rem 1rem;

          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          appearance: none;
          -webkit-appearance: none;

          &:focus {
            outline: none;
          }
        }
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      div {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p:nth-child(1) {
          color: #626262;
          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.9375rem;
          }
        }

        p:nth-child(2) {
          flex: 1;
          max-width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #1d1d1f;
          font-family: Inter;
          font-size: 0.9375rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }

        a {
          flex: 1;
          max-width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #2d5795;
          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }
      }
      .input {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 0.625rem;
        width: 50%;

        input {
          border-radius: 10px;
          background-color: #fefefe;
          width: 100%;
          height: auto;
          display: flex;
          flex: 1;
          color: #363539;
          border: none;
          border: 1px solid #dedce9;
          padding: 0.875rem 1rem;
        }
      }
    }

    .procure_item_image {
      width: 293px;
      height: 186px;
      display: flex;
      flex-shrink: 0;

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      .procure_item_image_inner {
        display: flex;
        width: 100%;
        height: auto;

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
