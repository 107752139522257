<template>
  <circle-loader v-if="isLoading" />
  <div class="procure" v-else>
    <div class="procure_inner">
      <h1>Business Name: {{ item?.businessName }}</h1>

      <div class="item_details">
        <div>
          <p>Account Name</p>
          <p>{{ item?.data?.accountName }}</p>
        </div>

        <div v-if="item?.data?.contactNumber">
          <p>Contact Number</p>
          <p>{{ item?.data?.contactNumber }}</p>
        </div>

        <div v-if="item?.data?.method">
          <p>Payment Method</p>
          <p>{{ item?.data?.method }}</p>
        </div>
        <div>
          <p>Amount in {{ item?.data?.currency }}</p>
          <p>{{ Number(item?.data?.amount).toLocaleString() }}</p>
        </div>
        <div>
          <p>Amount in NGN</p>
          <p>{{ Number(item?.data?.amountinLocal).toLocaleString() }}</p>
        </div>

        <div>
          <p>Bank Name</p>
          <p>{{ item?.data?.bankName }}</p>
        </div>

        <div>
          <p>Account Number</p>
          <p>{{ item?.data?.accountNumber }}</p>
        </div>

        <div v-if="item?.data?.fedWireRoutingNumber">
          <p>Fed Wire Routing Number</p>
          <p>{{ item?.data?.fedWireRoutingNumber }}</p>
        </div>

        <div v-if="item?.data?.swiftBicCode">
          <p>Swift BIC Code</p>
          <p>{{ item?.data?.swiftBicCode }}</p>
        </div>

        <div v-if="item?.data?.routingNumber">
          <p>ACH Routing Number</p>
          <p>{{ item?.data?.routingNumber }}</p>
        </div>

        <div v-if="item?.data?.address">
          <p>Address</p>
          <p>{{ item?.data?.address }}</p>
        </div>

        <div v-if="item?.data?.city">
          <p>City</p>
          <p>{{ item?.data?.city }}</p>
        </div>

        <div v-if="item?.data?.country">
          <p>Country</p>
          <p>{{ item?.data?.country }}</p>
        </div>

        <div v-if="item?.data?.email">
          <p>Email</p>
          <p>{{ item?.data?.email }}</p>
        </div>

        <div v-if="item?.data?.postCode">
          <p>Post Code</p>
          <p>{{ item?.data?.postCode }}</p>
        </div>

        <div v-if="item?.data?.sortCode">
          <p>Sort Code</p>
          <p>{{ item?.data?.sortCode }}</p>
        </div>

        <div>
          <p>Update Status</p>
          <div class="shipments_select">
            <select required v-model="status" class="input_field">
              <option value="">Select status</option>
              <option value="PAID">PAID</option>
              <option value="PENDING">PENDING</option>
              <option value="PROCESSING">PROCESSING</option>
              <option value="LOGGED">LOGGED</option>
            </select>
          </div>
        </div>

        <div class="input_image">
          <input
            type="file"
            ref="fileInput"
            id="file-input"
            required
            @change="handleFileUpload"
            accept="image/png, image/gif, image/jpeg, application/pdf"
            style="display: none"
          />
          <div>
            <label
              v-if="!productImage"
              @click="handleFileInputClick"
              for="file-input"
            >
              Attach item picture
            </label>
            <label v-else @click="handleFileInputClick" for="file-input">
              Choose another item picture
            </label>
          </div>
          <div class="images_wrapper">
            <div class="image_wrapper">
              <img
                :src="productImage"
                alt="Preview Image"
                v-if="productImage"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- <iframe
        v-if="item?.data?.invoiceFile"
        :src="item?.data?.invoiceFile"
        width="50%"
        height="200px"
        frameborder="0"
      ></iframe> -->

      <div class="btn_container">
        <div class="btn_wrapper" @click="downloadPdf">
          <div class="button">
            <button class="button_btn">Download Invoice</button>
          </div>
        </div>

        <div class="btn_wrapper" @click="updateRecordHandler">
          <div class="button">
            <button :disabled="status.length <= 0" class="button_btn">
              Update Record
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleLoader from "../../components/loader/CircleLoader.vue";
import SupplierPayments from "../../utils/api/SupplierPayments/SupplierPayments";

export default {
  data() {
    return {
      // pdfUrl: "",
      status: "",
      isLoading: false,
      item: null,
      imageUrl: "",
      productImage: "",
      imageFile: null,
      fileName: "",
    };
  },
  components: {
    CircleLoader,
  },
  mounted() {
    this.fetchSupplierPaymentWithId();
  },
  methods: {
    removeBase64Prefix(base64String) {
      // Check if the string starts with any of the prefixes
      const prefixes = [
        "data:image/png;base64,",
        "data:image/jpeg;base64,",
        "data:application/pdf;base64,",
      ];

      for (const prefix of prefixes) {
        if (base64String.startsWith(prefix)) {
          // Remove the prefix from the string
          return base64String.slice(prefix.length);
        }
      }

      // Return the original string if it doesn't start with any of the prefixes
      return base64String;
    },

    handleFileUpload(event) {
      const imgFile = event.target.files[0];

      this.imageFile = imgFile;

      this.fileName = imgFile.name;

      if (imgFile) {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.productImage = event.target.result;
          this.imageUrl = this.removeBase64Prefix(event.target.result);
        };
        reader.readAsDataURL(imgFile);
      }
    },
    async updateRecordHandler() {
      const token = this.$store.state.accessToken;

      const payment_Id = this.$route.params.itemId;
      try {
        const editedSupplierPaymentRes =
          await SupplierPayments.updateSupplierPayment({
            payment_Id,
            access_token: token,
            status: this.status,
            image: this.imageUrl,
          });
        this.$toast.success(editedSupplierPaymentRes.data.data);

        this.$router.push("/supplier-payments");

        // window.location.reload();
      } catch (err) {
        this.$toast.error(err.response?.data?.message);
      }
    },
    openModalHandler() {
      this.modalOpen = true;
    },
    async fetchSupplierPaymentWithId() {
      const token = this.$store.state.accessToken;

      const payment_Id = this.$route.params.itemId;

      this.isLoading = true;
      try {
        const userSupplierPaymentRes =
          await SupplierPayments.getSingleSupplierPayment({
            payment_Id,
            access_token: token,
          });

        this.isLoading = false;
        this.item = userSupplierPaymentRes.data;

        this.status = userSupplierPaymentRes.data.data.status;
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },
    convertToPdf(base64Pdf) {
      if (base64Pdf) {
        const binaryData = atob(base64Pdf.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        this.pdfUrl = URL.createObjectURL(blob);
      } else {
        this.pdfUrl = "";
      }
    },
    async downloadPdf() {
      const url = this.item?.data?.invoiceFile;

      if (!url) {
        console.error("No URL provided for the PDF download");
        return;
      }

      const link = document.createElement("a");
      link.href = url;
      link.download = `${this.item?.data.companyName}-invoice.pdf`;

      link.target = "_blank";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.procure {
  width: 100%;
  padding: 2.75rem 5.125rem;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .procure_inner {
    display: flex;
    padding: 0.875rem 5.125rem;
    flex: 1;
    flex-direction: column;
    // height: auto;
    flex-shrink: 0;
    border-radius: 1.875rem;
    gap: 1.5625rem;
    border: 1px solid #dedce9;
    background: #fafafa;

    @media only screen and (max-width: 1280px) {
      // max-width: 770px;
      height: auto;
    }

    @media only screen and (max-width: 920px) {
      overflow: visible;
      gap: 1.5625rem;
      padding: 0rem 0.5em;
    }

    h1 {
      margin-top: 4.875rem;
      color: #000;
      font-family: Moderat;
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .item_details {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5625rem;
      width: 40.8125em;

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      .input_image {
        width: 100%;
        max-width: 100%;
        height: 218px;
        flex-shrink: 0;
        border-radius: 1.25rem;
        border: 0.0625rem solid #c4c1c1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
        overflow: hidden; /* Prevents overflow content from affecting the layout */

        .images_wrapper {
          display: flex;
          width: 40.8125em;
          overflow-x: scroll;
          gap: 0.75rem;

          .image_wrapper {
            width: 200px;
            height: 150px;
            display: flex;
            @media only screen and (max-width: 920px) {
              width: 100px;
              height: 100px;
            }
            img {
              display: flex;
              width: 100%;
              height: auto;
              object-fit: contain;
            }
          }
        }

        div {
          width: 100%;
          height: auto;
          position: relative;
          align-items: center;
          display: flex;
          justify-content: center;

          label {
            cursor: pointer;
            z-index: 100;
          }
        }
        @media only screen and (max-width: 920px) {
          width: 100%;
        }
      }

      .shipments_select {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 0.625rem;
        width: 50%;

        select {
          border-radius: 10px;
          background-color: #fefefe;
          width: 100%;
          height: auto;
          display: flex;
          flex: 1;
          color: #363539;
          border: none;
          border: 1px solid #dedce9;
          padding: 0.875rem 1rem;

          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          appearance: none;
          -webkit-appearance: none;

          &:focus {
            outline: none;
          }
        }
      }

      h1 {
        margin-top: 0rem;
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      div {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p:nth-child(1) {
          color: #626262;
          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.9375rem;
          }
        }

        p:nth-child(2) {
          flex: 1;
          max-width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #1d1d1f;
          font-family: Inter;
          font-size: 0.9375rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }

        a {
          flex: 1;
          max-width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #2d5795;
          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }
      }
    }
    .btn_container {
      display: flex;
      gap: 50px;
      .btn_wrapper {
        display: flex;
        flex-direction: column;
        width: 150px;
        padding: 23px, 248px, 23px, 243px;
        a {
          width: 100%;
        }
      }
    }
  }
}
</style>
