import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

const SupplierPayments = {
  getAllSupplierPayments: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/supplier-payments`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getSingleSupplierPayment: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/find-supplier-payment/${payload.payment_Id}/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  updateSupplierPayment: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/supplier-payment/update/${payload.payment_Id}`,
      method: "PUT",
      data: JSON.stringify({
        status: payload.status,
        image: payload.image,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export default SupplierPayments;
