import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/Store";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import "./styles/dropdown.css";
import vSelect from "vue-select";

createApp(App)
  .use(ToastPlugin, {
    // One of the options
    duration: 5000,
    position: "top-left",
  })
  .component("v-select", vSelect)
  .use(store)
  .use(router)
  .mount("#app");
